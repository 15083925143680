import { useState, useRef, useEffect } from 'react'

const useWebsocket = ({ url }) => {
    const ws = useRef<WebSocket | null>(null)
    // socket 数据
    const [wsData, setMessage] = useState({})
    //  socket 状态
    const [readyState, setReadyState] = useState({ key: 0, value: '正在连接中' })

    const creatWebSocket = () => {
        const stateArr = [
            { key: 0, value: '正在连接中' },
            { key: 1, value: '已经连接并且可以通讯' },
            { key: 2, value: '连接正在关闭' },
            { key: 3, value: '连接已关闭或者没有连接成功' }
        ]
        try {
            ws.current = new WebSocket(url)
            ws.current.onopen = () => {
                console.log('=======onopen ws.current?.readyState', ws.current?.readyState)
                setReadyState(stateArr[ws.current?.readyState ?? 0])
                setMessage({})
            }
            ws.current.onclose = () => {
                if (!ws.current) {
                    setReadyState({ key: 3, value: '连接已关闭或者没有连接成功' })
                }
            }
            ws.current.onerror = () => {
                console.log('=======onerror ws.current?.readyState', ws.current?.readyState)
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onmessage = e => {
                console.log('=======onmessage====',JSON.parse(e.data))
                setMessage(JSON.parse(e.data) )
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    const webSocketInit = () => {
        console.log('===websocket webSocketInit ')
        if (!ws.current) {
            creatWebSocket()
        }
    }

    //  关闭 WebSocket
    const closeWebSocket = () => {
        console.log('===closeWebSocket ')
        ws.current?.close()
    }

    // 发送数据
    const sendMessage = (str: string) => {
        ws.current?.send(str)
    }

    //重连
    const reconnect = () => {
        console.log('=======进入reconnect')
        try {
            closeWebSocket()
            ws.current = null
            creatWebSocket()
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        webSocketInit()
        // return () => {
        //     ws.current?.close()
        // }
    }, [ws])

    return {
        wsData,
        readyState,
        closeWebSocket,
        reconnect,
        sendMessage
    }
}
export default useWebsocket
