import { Carousel, Image, message } from 'antd'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import { websocketUrl, fetchUrl } from '../../utils/global.ts'
import logo from '../../assets/img/home-logo.png'
import logoTitle from '../../assets/img/home-title.png'
import centerCircle from '../../assets/img/center-circle.png'
import companyProfile from '../../assets/img/company-profile.png'
import capacityDistribution from '../../assets/img/capacity-distribution.png'
import parkMap from '../../assets/img/park-map.png'
import industryInformation from '../../assets/img/industry-information.png'
import bottomBackground from '../../assets/img/bottom-background.png'
import cloudTop from '../../assets/img/cloud-top.png'
import cloudBottom from '../../assets/img/home-bottom-cloud.png'
import './index.less'
import '../../../node_modules/qweather-icons/font/qweather-icons.css'
import useWebsocket from '../../websocket.ts'

function Home() {
    const [data, setData] = useState([])
    const [canDispalyData, setCanDispalyData] = useState([])
    const [weatherInfo, setWeatherInfo] = useState({})
    const [fullscreen, setFullscreen] = useState(false)
    const [loadCount, setLoadCount] = useState(0) // 设置最大请求次数
    const MAX_LOAD_COUNT = 3
    const { wsData, readyState, closeWebSocket, reconnect, sendMessage } = useWebsocket({
        url: `${websocketUrl[process.env.REACT_APP_ENV]}ws/webSocket/deploy?moduleId=1` // 此参数为websocket地址
    })
    const [currentTime, setCurrentTime] = useState(dayjs(new Date()).format('HH:mm'))

    useEffect(() => {
        const oldData = localStorage.getItem('pc/largeScreenHome')
        // 接受到socket数据， 进行业务逻辑处理
        if (wsData?.length > 0) {
            setData(wsData)
            localStorage.setItem('pc/largeScreenHome', JSON.stringify(wsData))
        } else if (wsData?.length === 0) {
            setData([])
            localStorage.setItem('pc/largeScreenHome', JSON.stringify([]))
        } else if (oldData && JSON.parse(oldData)?.length > 0) {
            setData(JSON.parse(oldData))
        }
        // 如果是已关闭且是当前页面自动重连
        if (readyState.key === 3 && loadCount <= MAX_LOAD_COUNT) {
            reconnect()
            setLoadCount(loadCount + 1) // 每次请求计数器加1
        }
    }, [wsData, readyState])

    useEffect(() => {
        axios
            .get('https://devapi.qweather.com/v7/weather/3d?key=ebb17fee020c4087ae450d1e82c61383&location=115.81,28.73', {})
            .then(res => {
                if (res?.data?.daily[0]) {
                    setWeatherInfo(res.data.daily[0])
                }
            })
            .catch(e => {})
        axios
            .get(`${fetchUrl[process.env.REACT_APP_ENV]}screen/footage/canDisplay`, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            })
            .then(res => {
                const { code, data, message } = res.data
                if (code == '00000') {
                    setCanDispalyData(data)
                } else {
                    message.error(message || '错误')
                }
            })
            .catch(e => {})
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(dayjs(new Date()).format('HH:mm'))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const intervalPing = setInterval(() => {
            sendMessage('ping')
        }, 300000) // 发送ping请求的时间间隔为5秒
        return () => clearInterval(intervalPing)
    }, [])

    const jumpLink = () => {
        closeWebSocket()
    }

    const handleClick = _.debounce(() => {
        const element = document.getElementById('video')
        if (fullscreen) {
            element.muted = true // 使视频静音
            //退出全屏
            console.log('exit')
            if (document) {
                if (document?.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document?.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen()
                } else if (document?.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document?.msExitFullscreen) {
                    document.msExitFullscreen()
                }
            }
        } else {
            element.muted = false // 取消视频静音
            //全屏
            if (element?.requestFullscreen) {
                element.requestFullscreen()
            } else if (element?.webkitRequestFullScreen) {
                element.webkitRequestFullScreen()
            } else if (element?.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element?.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen()
            }
        }
        setFullscreen(!fullscreen)
    }, 300)

    const weekText = val => {
        let text = ''
        if (val) {
            switch (val) {
                case '1':
                    text = '星期一'
                    break
                case '2':
                    text = '星期二'
                    break
                case '3':
                    text = '星期三'
                    break
                case '4':
                    text = '星期四'
                    break
                case '5':
                    text = '星期五'
                    break
                case '6':
                    text = '星期六'
                    break
                case '0':
                    text = '星期日'
                    break
            }
        }
        return text
    }

    return (
        <div className="Home">
            <header className="App-header">
                <div className="home-top-wrap">
                    <div className="home-top">
                        <div className="home-top-left">
                            <img src={logo} className="App-logo" alt="logo" />
                            <img src={logoTitle} className="logo-title" alt="title" />
                        </div>
                        <div className="home-top-right">
                            <div
                                className="home-top-right-box"
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                <div className="home-weather-icon">
                                    <i className={'qi-' + weatherInfo?.iconDay} style={{ color: '#562900', fontSize: 60 }}></i>
                                </div>
                                <div className="home-weather-des">
                                    <div className="home-weather-number">
                                        {weatherInfo?.tempMin}℃-{weatherInfo?.tempMax}℃
                                    </div>
                                    <div className="home-weather-text">{weatherInfo?.textDay}</div>
                                </div>
                            </div>
                            <div
                                className="home-top-right-box"
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                <div className="home-weather-des">
                                    <div className="home-weather-number">{weekText(dayjs(new Date()).format('d'))}</div>
                                    <div className="home-weather-text">{dayjs(new Date()).format('MM月DD日')}</div>
                                </div>
                                <div className="home-weather-time">{currentTime}</div>
                            </div>
                            <div className="home-top-right-line"></div>
                        </div>
                    </div>
                </div>
                <div className="home-center">
                    <div className="home-center-left">
                        <img src={centerCircle} className="center-circle" alt="img" />
                        {data?.length > 0 && (
                            <div className="home-center-img">
                                {/* {data[0].type === 0 && (
                                    <Carousel autoplay effect="fade">
                                        {data?.map((item, index) => {
                                            return <Image className="swiper-image" src={item.url} key={index} />
                                        })}
                                    </Carousel>
                                )} */}
                                {data[0].type === 0 && <Image className="swiper-image" src={data[0].url} />}
                                {data[0].type === 1 && (
                                    <video id="video" onTouchEnd={handleClick} className="swiper-video" controls={false} autoPlay loop muted style={{ objectFit: 'fill' }}>
                                        <source src={data[0].url} type="video/mp4"></source>
                                    </video>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="home-center-right-wrap">
                        <div className="home-center-right">
                            {canDispalyData && canDispalyData[0]?.hasFootage && (
                                <Link
                                    to={{
                                        pathname: '/company-profile'
                                    }}
                                    onClick={() => jumpLink()}
                                >
                                    <img src={companyProfile} className="company-profile" alt="img" />
                                </Link>
                            )}
                            {!canDispalyData || (!canDispalyData[0]?.hasFootage && <img src={companyProfile} className="company-profile" alt="img" />)}
                            <div className="company-introduce-wrap">
                                {canDispalyData && canDispalyData[1]?.hasFootage && (
                                    <Link
                                        to={{
                                            pathname: '/capacity-distribution'
                                        }}
                                        onClick={() => jumpLink()}
                                    >
                                        <img src={capacityDistribution} className="company-introduce-left-img" alt="img" />
                                    </Link>
                                )}
                                {!canDispalyData || (!canDispalyData[1]?.hasFootage && <img src={capacityDistribution} className="company-introduce-left-img" alt="img" />)}
                                {canDispalyData && canDispalyData[2]?.hasFootage && (
                                    <Link
                                        to={{
                                            pathname: '/industry-information'
                                        }}
                                        onClick={() => jumpLink()}
                                    >
                                        <img src={industryInformation} className="company-introduce-left-img" alt="img" />
                                    </Link>
                                )}
                                {!canDispalyData || (!canDispalyData[2]?.hasFootage && <img src={industryInformation} className="company-introduce-left-img" alt="img" />)}
                            </div>
                            <Link
                                to={{
                                    pathname: '/company-map'
                                }}
                                onClick={() => jumpLink()}
                            >
                                <img src={parkMap} className="company-map-img" alt="img" />
                            </Link>
                        </div>
                    </div>
                </div>
                <img src={bottomBackground} className="home-bottom" alt="img" />
                <img src={cloudTop} className="cloud-top" alt="img" />
                <img src={cloudBottom} className="cloud-bottom" alt="img" />
            </header>
        </div>
    )
}

export default Home
