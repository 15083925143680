import { Link } from 'react-router-dom'
import goHome from '../../assets/img/go-home.png'
import '../index.less'

function CompanyMap() {
    return (
        <div className="container">
            <div className="bottom-wrap" style={{ left: 186, bottom: 12, transform: 'initial' }}>
                <Link
                    to={{
                        pathname: '/'
                    }}
                >
                    <img src={goHome} className="go-icon" alt="img" />
                </Link>
            </div>
            <iframe id="iframe" title="map" src="https://map.jtyjy.com/m/screen.html" style={{ width: '100%', height: '100%', border: 0 }}></iframe>
        </div>
    )
}

export default CompanyMap
