import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { websocketUrl } from '../../utils/global.ts'
import goHome from '../../assets/img/go-home.png'
import goNext from '../../assets/img/go-next.png'
import goPrev from '../../assets/img/go-prev.png'
import goNextDisabled from '../../assets/img/go-next-disabled.png'
import goPrevDisabled from '../../assets/img/go-prev-disabled.png'
import '../index.less'
import useWebsocket from '../../websocket.ts'

function CapacityDistribution() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const { wsData, readyState, closeWebSocket, reconnect } = useWebsocket({
        url: `${websocketUrl[process.env.REACT_APP_ENV]}ws/webSocket/deploy?moduleId=3` // 此参数为websocket地址
    })
    useEffect(() => {
        const oldData = localStorage.getItem('pc/largeScreenCapacity')
        // 接受到socket数据， 进行业务逻辑处理
        if (wsData?.length > 0) {
            setData(wsData)
            setCurrentIndex(0)
            localStorage.setItem('pc/largeScreenCapacity', JSON.stringify(wsData))
        } else if (wsData?.length === 0) {
            setData([])
            localStorage.setItem('pc/largeScreenCapacity', JSON.stringify([]))
        } else if (oldData && JSON.parse(oldData)?.length > 0) {
            setData(JSON.parse(oldData))
            setCurrentIndex(0)
        }
        // 如果是已关闭且是当前页面自动重连
        if (readyState.key === 3) {
            reconnect()
        }
    }, [wsData, readyState])

    const jumpLink = () => {
        closeWebSocket()
    }

    const handleNext = () => {
        if (currentIndex + 1 >= data?.length) {
            setCurrentIndex(data?.length)
        } else {
            let newIndex = currentIndex + 1
            setCurrentIndex(newIndex)
        }
    }

    const handlePrev = () => {
        let newIndex = currentIndex - 1
        if (newIndex < 0) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(newIndex)
        }
    }
    return (
        <div className="default-video-container">
            {data && data[currentIndex]?.url && data[currentIndex].type === 0 && <img src={data[currentIndex].url} className="container-bac" alt="img" />}
            {data && data[currentIndex]?.url && data[currentIndex].type === 1 && (
                <video className="container-bac" controls autoPlay style={{ width: '69%', height: '100%', objectFit: 'fill' }}>
                    <source src={data[currentIndex].url} type="video/mp4"></source>
                </video>
            )}
            <div className="bottom-wrap">
                {currentIndex <= 0 && <img src={goPrevDisabled} className="go-icon-disabled" alt="img" style={{ marginRight: 60 }} />}
                {currentIndex > 0 && <img src={goPrev} onClick={() => handlePrev()} className="go-icon" alt="img" style={{ marginRight: 60 }} />}
                <Link
                    to={{
                        pathname: '/'
                    }}
                    onClick={() => jumpLink()}
                >
                    <img src={goHome} className="go-icon" alt="img" style={{ marginRight: 60 }} />
                </Link>
                {currentIndex + 1 >= data?.length && <img src={goNextDisabled} className="go-icon-disabled" alt="img" />}
                {currentIndex + 1 < data?.length && <img src={goNext} onClick={() => handleNext()} className="go-icon" alt="img" />}
            </div>
        </div>
    )
}

export default CapacityDistribution
