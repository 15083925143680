import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './views/home/index'
import CompanyProfile from './views/company-profile/index'
import CompanyMap from './views/company-map/index'
import CapacityDistribution from './views/capacity-distribution/index'
import IndustryInformation from './views/industry-information/index'

function App() {
    return (
        <div className="App" style={{ height: '100%' }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/company-profile" element={<CompanyProfile />} />
                    <Route path="/company-map" element={<CompanyMap />} />
                    <Route path="/capacity-distribution" element={<CapacityDistribution />} />
                    <Route path="/industry-information" element={<IndustryInformation />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
