
export const websocketUrl = {
    development: 'ws://192.168.2.83:31802/',
    production: 'wss://prod.gw.jtyjy.com/',
    test: 'wss://test.gw.jtyjy.com/',
}
export const fetchUrl = {
    development: 'http://192.168.2.83:31802/',
    production: 'https://prod.gw.jtyjy.com/',
    test: 'https://test.gw.jtyjy.com/',
}